.ant-tooltip-inner {
  font-size: 12px;
  min-height: 24px;
  padding: 3px 8px;
  background-color: #464a51;
}

.ant-tooltip-arrow::before {
  background-color: #464a51;
}

.ant-menu-horizontal {
  line-height: 64px !important;
  border: none !important;
}

.ant-layout-header {
  line-height: inherit;
}

.ant-list-item {
  padding: 8px 0;
}

.ant-col {
  flex-grow: 1;
}

.ant-table-filter-column-title {
  padding: 8px;
}

.ant-table-pagination {
  padding-right: 16px;
}

.color-important {
  font-weight: bold;
}

.color-normal {
  color: inherit !important;
}

.color-muted {
  color: gray !important;
}

a:hover {
  text-decoration: underline;
}

.ant-table-footer {
  background: #fff;
  text-align: center;
}

.ant-table-placeholder {
  border: none;
}

.ant-empty-normal {
  margin: 0;
}

.ant-statistic-title {
  margin-bottom: -3px;
}

.ant-statistic + .ant-statistic {
  margin-top: 6px;
}

.sticky {
  position: sticky;
  top: 0;
}

.container {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
}

.part1,
.part2 {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.part2 {
  direction: rtl;
  margin-left: -30px;
}

.isloading-skeleton-width {
  width: 50%;
}

.ant-skeleton-title {
  margin-top: 11px;
  margin-bottom: 10px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.break-word {
  word-break: break-word;
}

.ant-layout-content {
  padding: 12px;
}

.app-header {
  padding: 0 12px;
}

.app-sub-header {
  padding: 6px 12px;
}

.cps-container {
  margin-bottom: 3px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cps-container .ant-skeleton {
  width: 80px;
}

.cps-container .ant-skeleton-title {
  margin: 12px 0;
}

h3.ant-typography,
.ant-typography h3 {
  font-size: 22px;
}

@media (min-width: 768px) {
  h3.ant-typography,
  .ant-typography h3 {
    font-size: 24px;
  }

  .app-header {
    padding: 0 12px;
  }

  .ant-layout-content {
    padding: 12px;
  }

  .app-sub-header {
    padding: 6px 18px;
  }

  .cps-container {
    justify-content: center;
  }

  .ant-timeline .right .ant-timeline-item-content {
    left: inherit !important;
    width: calc(50% - 12px) !important;
    margin: 0 !important;
    text-align: right !important;
  }

  .ant-timeline .left .ant-timeline-item-content {
    left: calc(50% - 4px) !important;
    width: calc(50% - 14px) !important;
    margin: 0 0 0 18px !important;
    text-align: left !important;
  }

  .ant-timeline .first-row {
    display: flex;
    align-items: center;
  }

  .ant-timeline .left .first-row {
    justify-content: flex-start;
  }

  .ant-timeline .right .first-row {
    justify-content: flex-end;
  }

  .ant-timeline .left .timeline-tag {
    order: 1;
  }

  .ant-timeline .right .timeline-tag {
    order: 3;
  }

  .ant-timeline .left .timeline-timeago {
    order: 3;
  }

  .ant-timeline .right .timeline-timeago {
    order: 1;
  }

  .ant-timeline .timeline-amount {
    order: 2;
  }
}

.ant-timeline-item-head {
  background-color: transparent;
}

.ant-timeline .timeline-tag {
  text-transform: capitalize;
  margin: 0 !important;
}

.ant-timeline .timeline-amount {
  margin: 0 6px;
  word-break: break-all;
}

.ant-timeline .timeline-timeago {
  font-size: 12px;
  line-height: 21px;
}

.ant-timeline {
  padding: 10px 0;
}

.ant-timeline-item-last {
  padding-bottom: 0;
}

.fadein {
  animation: fadein 0.25s;
}

.gradient-container {
  position: relative;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.bottom-gradient {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 50px;
  bottom: 0;
  left: 0;
  background: linear-gradient(rgba(255, 255, 255, 0), #fff);
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* @TODO remove this for flex */
.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.cryptocurrenty-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 6px 0;
  margin-top: -1px;
}

.ant-tag.tag-send,
.ant-tag.tag-offline {
  color: #e04576;
  border-color: #ffa2bf;
  background-color: #fff0f2 !important;
}

.ant-tag-magenta {
  color: magenta;
  border-color: rgba(255, 0, 255, 0.5);
}

.ant-popover-inner-content {
  padding: 12px 0;
}

.ant-slider-mark-text {
  white-space: nowrap;
}

.detailed-preference {
  padding-bottom: 6px;
}

.detailed-preference + .detailed-preference {
  padding-top: 6px;
  margin-top: 6px;
  border-top: solid 1px #eff2f5;
}

.preference-detailed-title {
  display: block;
  font-size: 16px;
  margin-bottom: 6px;
  font-weight: bold;
}

.detail-layout .ant-row + .ant-row,
.detail-layout.ant-row + .ant-row {
  margin-top: 8px;
  padding-top: 8px;
  border-top: solid 1px #f0f2f5;
}

.detail-layout .divider {
  border-bottom: solid 1px #f0f2f5;
}

.detail-layout .ant-row .ant-col:first-child {
  color: gray;
}

.detail-layout .ant-row .ant-col .default-color {
  color: rgba(0, 0, 0, 0.65);
}

.detail-layout .ant-skeleton-title,
.price-list .ant-skeleton-title {
  margin: 4px 0;
}

.detail-layout .ant-skeleton + .ant-skeleton {
  margin-top: 4px;
}

.anticon.anticon-question-circle,
.anticon.anticon-exclamation-circle {
  margin-left: 6px;
  font-size: 14px !important;
}

.search-autocomplete-dropdown {
  width: 650px !important;
}

.quake3-scoreboard .ant-row + .ant-row {
  font-size: 16px;
  font-weight: bold;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography {
  font-weight: normal;
}

.home-banner .ant-btn:hover,
.home-banner .ant-btn:focus,
.home-banner .ant-btn:active,
.home-banner .ant-btn.active {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.7);
}

.what-is-card {
  text-align: center;
}

.what-is-card p,
.what-is-card blockquote,
.what-is-card ul {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.what-is-card blockquote {
  margin: 1em 0;
  padding-left: 0.8em;
  font-size: 16px;
  border-left: 4px solid #f0f0f0;
  display: inline-block;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.options-wrapper {
  margin: -2px 0;
  min-width: 60px;
  max-height: 60px;
}

.options-wrapper .ant-col {
  flex-grow: initial;
  margin: 2px 0;
}

.options-wrapper .ant-col .ant-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.options-wrapper .ant-col .ant-btn .anticon {
  margin-top: 2px;
}

.ant-input-search .anticon-search,
.ant-input-search .search-history-icon {
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-search .anticon-search:hover,
.ant-input-search .search-history-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}

.ant-statistic-content-prefix {
  margin-right: 3px;
}

.ant-statistic-content-value-decimal {
  font-size: 16px;
}

.ant-statistic-content-suffix {
  margin-left: 4px;
  font-size: 16px;
}

.transaction-card .ant-spin-nested-loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.2);
}

.transaction-card .ant-row:not(:last-child) {
  border-bottom: solid 1px #f0f2f5;
}

.transaction-card .ant-row.row-header {
  padding-top: 0;
}

.transaction-card .ant-row {
  padding: 12px 0;
}

.transaction-card .ant-row.row-pagination {
  padding-bottom: 0;
  padding-top: 12px;
}

.ant-card {
  margin-bottom: 12px;
}

.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
}

.qrcode-reader {
  border-color: #d9d9d9 !important;
}

.qrcode-reader .ant-btn {
  margin-top: 12px;
}

.qrcode-reader > div:first-of-type > span:first-of-type {
  display: none;
}

#qrcode-reader__status_span,
#qrcode-reader-search__status_span,
#qrcode-reader-search-home__status_span {
  float: none !important;
}

.ant-alert-with-description {
  align-items: baseline;
  padding: 12px;
}

.ant-alert-with-description .ant-alert-icon {
  margin-right: 12px;
  font-size: 16px;
}

.ant-input-affix-wrapper.has-error:focus,
.ant-input-affix-wrapper-focused.has-error {
  border-color: #ff7875 !important;
  box-shadow: 0 0 0 2px #ff4d4f33 !important;
}

.guide table,
.guide th,
.guide td {
  border: 1px solid #d9d9d9;
  border-collapse: collapse;
}

.guide th,
.guide td {
  padding: 0 6px;
}

.ant-tree-switcher {
  display: none;
}

.tree-container {
  margin: 10px 0;
}

.ant-tree {
  padding: 10px 0;
}
